import React, { useContext } from 'react';
import classnames from 'classnames';

import styles from './MessageDeletion.pcss';

import { useDeleteMessageMutation } from 'Mutations/DeleteMessage.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  id: string;
  className?: string;
  message: JSX.Element;
  onClose?: () => void;
}

export const MessageDeletionContent: React.FC<Props> = (props) => {
  const { id, message, className, onClose } = props;

  const [deleteMessage, deleteProcessing] = useDeleteMessageMutation();

  const handleDelete = () => {
    deleteMessage({
      variables: {
        input: {
          id,
        },
      },
      updater: (store) => {
        const message = store.get(id);
        message?.setValue(+new Date(), 'deletedAt');
      },
      onCompleted: () => {
        handleCancel();
      },
    });
  };

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <div className={classnames(styles.drawerContainer, className)}>
      <div className={styles.content}>
        <Text type="d2" msg="delete_message_drawer.title" className={styles.title} />
        <Text type="md" msg="delete_message_drawer.descr" className={styles.descr} />
        {message}
      </div>
      <div className={styles.buttons}>
        <Button loading={deleteProcessing} msg="general.cancel" onClick={handleCancel} />
        <Button
          loading={deleteProcessing}
          color="black"
          msg="general.delete"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

const MessageDeletion: React.FC = () => {
  const { closeDrawer, params } = useContext(DrawerContext);

  const id = params['message-deletion']?.id as string;
  const message = params['message-deletion']?.message as JSX.Element;

  const handleClose = () => {
    closeDrawer('message-deletion');
  };

  return (
    <Drawer rootKey="message-deletion" className={styles.drawer}>
      <MessageDeletionContent id={id} message={message} onClose={handleClose} />
    </Drawer>
  );
};

export default MessageDeletion;
