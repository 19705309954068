import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import styles from './AddProfileDrawer.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { useTikTokOpenApi } from 'Hooks/useTikTokOpenApi';
import { CONTRACTOR_PROFILES_ROUTE } from 'Constants/general';
import VerifyByFacebook from 'scripts/modals/contractor/AddInstagramProfile/VerifyByFacebook/VerifyByFacebook';
import Button from 'Components/ui/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import Image from 'Components/ui/Image/Image';
import ErrorHandler from 'Util/errorHandler';

interface Props {
  maxLicensedCreators?: number | null;
  onPurchaseProceed?: () => void;
}

const AddProfileDrawer: React.FC<Props> = () => {
  const { connect } = useTikTokOpenApi();
  const tracking = useTracking();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const { closeDrawer } = useContext(DrawerContext);

  const handleClose = useCallback(() => {
    setOpened(false);
    closeDrawer('add-profile');
  }, []);

  const socialAccountSeleted = (platform: string) => {
    tracking.trackEvent({
      amplitude: true,
      event_id: 'd-v1-e6',
      event_cat: 'profile_modal',
      event_name: 'select',
      event_param: { platform },
    });
  };

  const handleInstagramClick = () => {
    socialAccountSeleted('instagram');
  };

  const handleTiktokAuthClick = useCallback(() => {
    connect().then((postMessageData) => {
      if (!postMessageData || (postMessageData && postMessageData.error)) {
        ErrorHandler.error('Failed tiktok auth', { postMessageData });
        return;
      }
      const url = `${CONTRACTOR_PROFILES_ROUTE}/${postMessageData.creatorId}`;
      navigate(url);
    });
  }, []);

  return (
    <Drawer opened={opened} onClose={handleClose} rootKey="add-profile" className={styles.drawer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text
            type="d2"
            msg="add_creator_profile_modal.title"
            className={styles.drawerTitleText}
          />
          <Text msg="add_creator_profile_modal.description" className={styles.drawerText} />
          <div className={styles.bulletedList}>
            <div className={styles.drawerBullet}>
              <Icon name="Lock" size={24} />
              <Text
                msg="add_creator_profile_modal.bullet.safe"
                className={styles.drawerBulletText}
              />
            </div>

            <div className={styles.drawerBullet}>
              <Icon name="Support-help" size={24} />
              <Text
                msg="add_creator_profile_modal.bullet.easy"
                className={styles.drawerBulletText}
              />
            </div>

            <div className={styles.drawerBullet}>
              <Icon name="Hand-Tap-Click" size={24} />
              <Text
                msg="add_creator_profile_modal.bullet.required"
                className={styles.drawerBulletText}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <VerifyByFacebook>
              <Button
                fluid
                className={styles.button}
                msg="general.instagram"
                icon="Arrow-small-right"
                iconPosition="right"
                onClick={handleInstagramClick}
              />
            </VerifyByFacebook>

            <Button
              fluid
              className={styles.button}
              onClick={handleTiktokAuthClick}
              msg="general.tiktok"
              icon="Arrow-small-right"
              iconPosition="right"
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddProfileDrawer;
