import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton, { ButtonType } from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { AddNotes_project$key } from 'GraphTypes/AddNotes_project.graphql';

interface Props {
  isAdmin: boolean;
  project: AddNotes_project$key | null;
  btnType?: ButtonType;
  className?: string;
}

const AddNotes: React.FC<Props> = (props) => {
  const { isAdmin, project, btnType, className } = props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useFragment(
    graphql`
      fragment AddNotes_project on Project {
        id
        comment
        adminComment
        creatorDeclinedToParticipate
      }
    `,
    project
  );

  if (!data) {
    return null;
  }
  const { id, comment, adminComment } = data;

  const finalComment = isAdmin ? adminComment : comment;
  const hasComment = !!finalComment;

  const tooltipData = finalComment
    ? { tooltipText: finalComment }
    : { tooltipMsg: 'chat.project.notes.tooltip' };

  const handleClick = () => {
    openDrawer('project-notes', undefined, true);
  };

  return (
    <>
      <Tooltip id="notes" {...tooltipData} delayShow={0} place="top" className={className}>
        <AlterButton
          icon={finalComment ? 'Notes-filled' : 'Notes'}
          msg={hasComment ? 'chat.in_modal.action.edit_note' : 'chat.in_modal.action.add_note'}
          type={btnType || 'grey'}
          handleClick={handleClick}
          className={className}
          data-test="addNotes:alterButton:unknown"
        />
      </Tooltip>
    </>
  );
};

export default AddNotes;
