import React, { useContext } from 'react';

import { amplitude } from 'Helpers/amplitude';
import Button from 'Atoms/Button/Button';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  projectId: string;
  balance?: number;
  isOldView?: boolean;
}

const ExtraPaymentAction: React.FC<Props> = (props) => {
  const { projectId, isOldView = true } = props;

  const { openDrawer } = useContext(DrawerContext);

  const handleMakePaymentClick = () => {
    amplitude.sendEvent({
      id: 157,
      name: 'extra_payment_click',
      category: 'chat',
      param: { projectId },
    });

    openDrawer(`extra_payment`, undefined, !isOldView);
  };

  return isOldView ? (
    <Button
      color="normal"
      msg="chat.project.make_extra_payment"
      onClick={handleMakePaymentClick}
      data-test="extraPaymentAction:button:makeExtraPayment"
    />
  ) : (
    <AlterButton
      msg="chat.action.extra_payment"
      icon="Money"
      type="grey"
      onClick={handleMakePaymentClick}
      data-test="extraPaymentAction:alterButton:extraPayment"
    />
  );
};

export default ExtraPaymentAction;
