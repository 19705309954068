import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';

import styles from './ListViewSwitch.pcss';

import Icon from 'Components/ui/Icon/Icon';
import {
  DiscoveryCreatorsContext,
  DiscoveryListViewType,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { amplitude } from 'Helpers/amplitude';

const ListViewSwitch: React.FC = () => {
  const { setListView, listView, queryParams } = useContext(DiscoveryCreatorsContext);

  useEffect(() => {
    if (!queryParams.owned && listView === DiscoveryListViewType.GRID) {
      setListView(DiscoveryListViewType.LIST);
    }
  }, [queryParams.owned, listView]);

  const handleSelectListView = () => {
    if (!queryParams.owned) {
      return;
    }
    setListView(DiscoveryListViewType.LIST);
    amplitude.sendEvent<547>({
      id: '547',
      category: 'discovery',
      name: 'select_view_type',
      param: {
        type: 'list',
      },
    });
  };

  const handleSelectGridView = () => {
    if (!queryParams.owned) {
      return;
    }
    setListView(DiscoveryListViewType.GRID);
    amplitude.sendEvent<547>({
      id: '547',
      category: 'discovery',
      name: 'select_view_type',
      param: {
        type: 'grid',
      },
    });
  };
  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.item, {
          [styles.active]: listView === DiscoveryListViewType.LIST,
        })}
        onClick={handleSelectListView}
      >
        <Icon name="Bullited-list" size={16} color="grey" />
      </div>

      <div
        className={classNames(styles.item, {
          [styles.active]: listView === DiscoveryListViewType.GRID,
          [styles.disabled]: !queryParams.owned,
        })}
        onClick={handleSelectGridView}
      >
        <Tooltip
          id="list-view-switch-tooltip"
          place="top"
          tooltipMsg={queryParams.owned ? undefined : 'discovery.list_view_switch_tooltip'}
        >
          <Icon name="Assets" size={16} color="grey" />
        </Tooltip>
      </div>
    </div>
  );
};

export default React.memo(ListViewSwitch);
