import React from 'react';
import { Button } from '@insensepro/ui-kit';
import { GoogleSheetIcon } from '@hugeicons-pro/core-stroke-rounded';

import { Reporting_ReportState } from 'GraphTypes/CreateCampaignCreatorsReportMutation.graphql';

type Props = {
  state?: Reporting_ReportState;
  error?: boolean;
};

const PublicationsReportButton = (props: Props) => {
  const { state, error } = props;

  const isError = state === 'FAILED' || error;

  return (
    <Button
      type="secondary"
      style="outlined"
      size="small"
      disabled={state === 'READY'}
      loading={state && state !== 'READY' && !isError}
      leftIcon={GoogleSheetIcon}
      msg="campaign.author_statistic_get"
      dataTest="publicationsReportButton:alterButton:authorStatisticBtn"
    />
  );
};

export default PublicationsReportButton;
