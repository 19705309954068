import React, { useMemo, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import { createSum } from 'Util/numberFormatter';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { BARTER_TYPE } from 'Constants/general';
import { ProjectPrice_project$key } from 'GraphTypes/ProjectPrice_project.graphql';

interface Props {
  project: ProjectPrice_project$key;
}

const ProjectPrice: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ProjectPrice_project on Project {
        id
        price
        currency
        priceChangeRequest {
          id
          price
        }
        launch {
          id
        }
        completion {
          id
        }
        campaign {
          paymentType
        }
      }
    `,
    project
  );

  const { openDrawer } = useContext(DrawerContext);

  const { id: projectId, campaign, price, currency, launch, completion } = data;

  const paymentType = campaign.paymentType;

  const hasPriceChangeRequest = !!data.priceChangeRequest?.id;

  const isLaunched = !!launch?.id;
  const isCompleted = !!completion?.id;

  const handlePriceChangeRequest = () => {
    openDrawer('project-price-request', undefined, true);
  };

  const newPrice = data.priceChangeRequest?.price;
  const newPriceVal = useMemo(() => {
    return createSum(newPrice, currency);
  }, [newPrice, currency]);

  const priceVal = useMemo(() => {
    return createSum(price, currency);
  }, [price, currency]);

  const priceValue =
    paymentType === BARTER_TYPE && !price ? { msg: 'chat.barter_price' } : { text: priceVal };

  const iconElement = hasPriceChangeRequest ? (
    <Icon name="Time-clock" />
  ) : isLaunched && !isCompleted ? (
    <Icon name="Edit" />
  ) : undefined;

  return (
    <Tooltip
      key="editPrice"
      id="editPrice"
      place="bottom"
      delayShow={0}
      tooltipMsg={hasPriceChangeRequest ? 'project.action.edit_price.tooltip' : undefined}
      tooltipValues={{ price: newPriceVal }}
    >
      <AlterButton
        {...priceValue}
        type="grey"
        leftElement={iconElement}
        handleClick={
          hasPriceChangeRequest || !isLaunched || isCompleted ? undefined : handlePriceChangeRequest
        }
        data-test="projectPrice:alterButton:unknown"
      />
    </Tooltip>
  );
};

export default ProjectPrice;
