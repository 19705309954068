import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';

import { DrawerProvider, getDrawerContextInstance } from 'Components/ui/Drawer';
import ContinueProfileCreation from 'Modal/contractor/ContinueProfileCreation/ContinueProfileCreation';

export type DrawerIds =
  | 'max-launched-campaigns-exceeded'
  | 'max-hired-creators-exceeded'
  | 'max-licensed-creators-exceeded'
  | 'max-active-brands-exceeded'
  | 'add-more-brands'
  | 'add-profile'
  | 'add-more-ad-creatives'
  | 'continue-profile-creation'
  | 'licenses-downgrade'
  | 'invite-creators'
  | 'message-deletion'
  | 'invite-outreach-creators'
  | `invite-outreach-errors-${string}`
  | 'manual-transactions'
  | 'bca-license'
  | 'chat-post-links-recognised'
  | 'add-creator-blocklist'
  | 'onboarding'
  | 'confirm-new-plan'
  | `topup-balance-${string}`
  | `transaction-comment-${string}`
  | 'save-mediaplan'
  | 'custom-list-create'
  | 'custom-list-create-via-csv'
  | 'custom-list-edit'
  | `custom-list-edit-${string}`
  | 'add-posting-to-this-collaboration'
  | `bca-permission`
  | 'connect-to-ads-manager-for-this-collaboration'
  | 'shopify-auth'
  | 'shopify-order-creation'
  | `project-notes`
  | 'project-price-request'
  | `extra_payment`
  | `new-project-price-${string}`
  | `archive-project`
  | `archive-project-by-contractor-${string}`
  | `project-draft-deadline`
  | `project-publication-date`
  | `project-bca-connection-${string}`
  | 'project-creator-rating'
  | `project-reimburse`
  | `edit-brand-modal-${string}`
  | 'chat-modal'
  | `media-file-${string}`
  | `shop-revision-${string}`
  | 'pause-subscription-drawer'
  | 'success-pause-subscription-drawer'
  | `request-revision`
  | `buy-${string}-addon`
  | 'invite-team-member'
  | `invite-team-member-${string}`
  | 'cancel-organization-subscription'
  | 'cancel-subscription-drawer'
  | 'cancel-subscription-typeform-drawer'
  | 'cancel-subscription-offer-drawer'
  | 'cancel-subscription-success-drawer'
  | 'cancel-subscription-billing-drawer'
  | 'cancel-subscription-offer-accepted-drawer'
  | `message-attachments-${string}`
  | `project-product-delivery`
  | 'sequence-create'
  | 'sequence-settings'
  | 'sequence-warning'
  | 'sequence-delete-item'
  | 'sequence-leave'
  | 'sequence-scratch'
  | 'recommended-price'
  | 'upload_csv_failed'
  | 'tt-shop-product-list'
  | 'outreach-explanation'
  | 'outreach-check-email'
  | 'outreach-welcome'
  | 'outreach-application-sent'
  | 'image-cropper'
  | 'update-product-image'
  | 'filters-form-drawer'
  | `delete-brief-creative-${string}`
  | `media-${string}`
  | 'bulk-message'
  | 'select-custom-list-for-creator'
  | 'custom-list-create-for-creator';

const DrawerContext = getDrawerContextInstance<DrawerIds>();

const DrawerContainer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <DrawerProvider context={DrawerContext}>
      {children}
      {!isMobile ? <ContinueProfileCreation /> : null}
    </DrawerProvider>
  );
};

export default DrawerContainer;
export { DrawerContext };
