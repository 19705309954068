import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryLoader } from 'react-relay';

import styles from './ProjectsSidebar.pcss';
import ProjectsFilter from './ProjectsFilter/ProjectsFilter';
import ProjectsList, { ProjectsListQuery } from './ProjectsList/ProjectsList';
import ProjectsSidebarPlaceholder from './ProjectsSidebarPlaceholder/ProjectsSidebarPlaceholder';

import { amplitude } from 'Helpers/amplitude';
import { getQueryLocationParams, encodeQueryData } from 'Util/encodeQueryData';
import { getBooleanValue } from 'Util/textFormatter';
import {
  ACCEPTED,
  STAGE_LAUNCHED,
  CONTENT_SUBMITTED,
  STAGE_COMPLETED,
  PUBLISHING_STARTED,
  CANCELLED_REQUEST,
} from 'Constants/general';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorsListQuery.graphql';
import { DateInterval, ProjectsListQuery as QueryType } from 'GraphTypes/ProjectsListQuery.graphql';

export const chatListPageCount = 20;

const defaultFilters = {
  brandIds: [],
  campaignIds: [],
  textQuery: '',
  shortlisted: undefined,
  withUnreadEvents: undefined,
  stages: undefined,
  first: chatListPageCount,
  draftDeadlineIntervals: [],
  publicationDeadlineIntervals: [],
};

interface Props {
  selectedProjectId?: string;
  organizationId?: string;
}

export type Filters = {
  brandIds?: string[];
  campaignIds?: string[];
  textQuery?: string;
  shortlisted?: boolean;
  withUnreadEvents?: boolean;
  archived?: boolean;
  stages?: AdvertiserProjectStage[];
  first: number;
  draftDeadlineIntervals?: DateInterval[];
  publicationDeadlineIntervals?: DateInterval[];
};

type FilterName = keyof Filters;

const filtersBlackList = ['first', 'draftDeadlineIntervals', 'publicationDeadlineIntervals'];

const ProjectsSidebar: React.FC<Props> = (props) => {
  const { selectedProjectId, organizationId: defaultOrganizationId = '' } = props;

  const navigate = useNavigate();
  const controller = useRef<AbortController>();
  const [queryReference, loadQuery] = useQueryLoader<QueryType>(ProjectsListQuery);

  const locationParams = getQueryLocationParams();
  const formattedParams = JSON.parse(JSON.stringify(locationParams));

  const { brandIds, campaignIds, textQuery, shortlisted, withUnreadEvents, stages, archived } =
    formattedParams;

  const defaultFiltersValue: Filters = {
    ...defaultFilters,
    brandIds: brandIds ? brandIds.split(',') : defaultFilters.brandIds,
    campaignIds: campaignIds ? campaignIds.split(',') : defaultFilters.campaignIds,
    textQuery: textQuery || defaultFilters.textQuery,
    shortlisted: getBooleanValue(shortlisted),
    withUnreadEvents: getBooleanValue(withUnreadEvents),
    archived: getBooleanValue(archived),
    stages: stages ? stages.split(',') : defaultFilters.stages,
    first: chatListPageCount,
  };

  const [filters, setFilters] = useState<Filters>(defaultFiltersValue);
  const [organizationId, setOrganizationId] = useState(defaultOrganizationId);

  const finalStagesList = useMemo<AdvertiserProjectStage[]>(() => {
    return Number(filters.stages?.length) > 0
      ? filters.stages || []
      : [ACCEPTED, STAGE_LAUNCHED, CONTENT_SUBMITTED, STAGE_COMPLETED, PUBLISHING_STARTED];
  }, [filters.stages]);

  useEffect(() => {
    const newLocationParams: Filters = JSON.parse(
      JSON.stringify({ ...filters, projectId: selectedProjectId, organizationId })
    );
    const filterKeys = Object.keys(newLocationParams) as FilterName[];
    const newFilteredLocationParams = filterKeys.reduce((newFilters, filterKey) => {
      if (newLocationParams[filterKey] && !filtersBlackList.includes(filterKey)) {
        return { ...newFilters, [filterKey]: newLocationParams[filterKey] };
      }
      return newFilters;
    }, {});
    const currentLink = location.pathname + location.search;
    const newLink = location.pathname + `${encodeQueryData(newFilteredLocationParams)}`;
    if (currentLink !== newLink) {
      navigate(newLink);
      amplitude.sendEvent({
        id: '273',
        category: 'chat',
        name: 'use_filters',
        param: {
          applied_filters: { ...filters },
        },
      });
    }
  }, [selectedProjectId, organizationId, filters]);

  useEffect(() => {
    loadQuery(
      { ...filters, organizationId, stages: finalStagesList },
      {
        fetchPolicy: 'store-and-network',
        networkCacheConfig: {
          metadata: {
            signal: controller?.current?.signal,
          },
        },
      }
    );
  }, [
    filters.archived,
    filters.brandIds,
    filters.campaignIds,
    filters.draftDeadlineIntervals,
    filters.publicationDeadlineIntervals,
    filters.shortlisted,
    filters.textQuery,
    filters.withUnreadEvents,
    organizationId,
    controller?.current,
    finalStagesList,
  ]);

  const clearAbortController = () => {
    if (controller.current) {
      controller.current.abort(CANCELLED_REQUEST);
    }
    controller.current = new AbortController();
  };

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    clearAbortController();
  };

  const handleClearParams = () => {
    handleFilterChange(defaultFilters);
  };

  const handleOrganizationChage = (newOrganizationId: string) => {
    setOrganizationId(newOrganizationId);
    handleFilterChange(defaultFilters);
  };
  return (
    <div className={styles.root}>
      <div className={styles.filters}>
        <ProjectsFilter
          organizationId={organizationId}
          filters={filters}
          onChange={handleFilterChange}
          onOrganizationChange={handleOrganizationChage}
          onClearParams={handleClearParams}
        />
      </div>
      <React.Suspense fallback={<ProjectsSidebarPlaceholder />}>
        {queryReference && (
          <ProjectsList
            {...filters}
            organizationId={organizationId}
            selectedProjectId={selectedProjectId}
            queryReference={queryReference}
          />
        )}
      </React.Suspense>
    </div>
  );
};

export default ProjectsSidebar;
