import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { Text } from '@insensepro/ui-kit';

import styles from './CampaignOverview.pcss';
import PerformanceIndicator from './PerformanceIndicator/PerformanceIndicator';
import { getOverviewData } from './utils';

// import Text from 'Components/ui/Text/Text';
import { CampaignOverviewQuery as QueryType } from 'GraphTypes/CampaignOverviewQuery.graphql';

const CampaignOverviewQuery = graphql`
  query CampaignOverviewQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      platform
      type
      publishingRequired
      postedCreatives: creatives(publishedPosts: true) {
        hasItems
      }
      counters {
        performanceSummary {
          assetsCreated
          assetsPosted
          avgCpe
          avgCpm
          avgEngagementRate
          totalComments
          totalCost
          totalEngagement
          totalHired
          totalLikes
          totalReach
          totalSaves
          totalShares
          totalViews
        }
      }
    }
  }
`;

interface Props {
  campaignId: string;
}

const CampaignOverview: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const data = useLazyLoadQuery<QueryType>(CampaignOverviewQuery, {
    campaignId,
  });

  const publishingRequired = data.campaign?.publishingRequired;
  const hasPosts = data.campaign?.postedCreatives.hasItems;
  const platform = data.campaign?.platform;
  const campaignType = data.campaign?.type;

  const countersData = getOverviewData({ campaignData: data });

  const withPosting = publishingRequired || hasPosts;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {withPosting && (
          <>
            <div className={styles.secton}>
              <Text
                type="b1"
                weight="medium"
                color="textMainDefault"
                msg="campaign.overview.posting_overview"
                className={styles.title}
              />
              <div className={styles.items}>
                {(platform === 'INSTAGRAM' || platform === 'FACEBOOK') && (
                  <PerformanceIndicator
                    campaignId={campaignId}
                    id="totalReach"
                    data={countersData.totalReach}
                  />
                )}
                <PerformanceIndicator
                  id="totalViews"
                  campaignId={campaignId}
                  data={countersData.totalViews}
                />
                <PerformanceIndicator
                  campaignId={campaignId}
                  id="avgEngagementRate"
                  data={countersData.avgEngagementRate}
                />
                {campaignType !== 'PRODUCT_SEEDING' && (
                  <PerformanceIndicator
                    campaignId={campaignId}
                    id="totalCost"
                    data={countersData.totalCost}
                  />
                )}
                {campaignType !== 'PRODUCT_SEEDING' && (
                  <PerformanceIndicator
                    campaignId={campaignId}
                    id="avgCpe"
                    data={countersData.avgCpe}
                  />
                )}
                {campaignType !== 'PRODUCT_SEEDING' && (
                  <PerformanceIndicator
                    campaignId={campaignId}
                    id="avgCpm"
                    data={countersData.avgCpm}
                  />
                )}
              </div>
            </div>
            <div className={styles.secton}>
              <Text
                type="b1"
                weight="medium"
                color="textMainDefault"
                msg="campaign.overview.posting_engagement"
                className={styles.title}
              />
              <div className={styles.items}>
                <PerformanceIndicator
                  campaignId={campaignId}
                  id="totalEngagement"
                  data={countersData.totalEngagement}
                />
                <PerformanceIndicator
                  campaignId={campaignId}
                  id="totalLikes"
                  data={countersData.totalLikes}
                />
                <PerformanceIndicator
                  campaignId={campaignId}
                  id="totalComments"
                  data={countersData.totalComments}
                />
                <PerformanceIndicator
                  campaignId={campaignId}
                  id="totalShares"
                  data={countersData.totalShares}
                />
                {(platform === 'INSTAGRAM' || platform === 'FACEBOOK') && (
                  <PerformanceIndicator
                    campaignId={campaignId}
                    id="totalSaves"
                    data={countersData.totalSaves}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <div className={styles.secton}>
          <Text
            type="b1"
            weight="medium"
            color="textMainDefault"
            msg="campaign.overview.creators_assets"
            className={styles.title}
          />
          <div className={styles.items}>
            <PerformanceIndicator
              campaignId={campaignId}
              id="totalHired"
              data={countersData.totalHired}
            />
            <PerformanceIndicator
              campaignId={campaignId}
              id="assetsCreated"
              data={countersData.assetsCreated}
            />
            {!withPosting && campaignType !== 'PRODUCT_SEEDING' && (
              <PerformanceIndicator
                campaignId={campaignId}
                id="totalCost"
                data={countersData.totalCost}
              />
            )}
            {withPosting && (
              <PerformanceIndicator
                campaignId={campaignId}
                id="assetsPosted"
                data={countersData.assetsPosted}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignOverview;
