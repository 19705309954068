import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './CreatorPastProjectsList.pcss';

import { getProjectsLink, getCampaignCreatorsLink, getOrganizationLink } from 'Util/links';
import { formatValue } from 'Util/dataFormatter';
import { createDateV2 } from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import ProjectStatus from 'Atoms/ProjectStatus/ProjectStatus';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { CreatorPastProjectsList_projects$data } from 'GraphTypes/CreatorPastProjectsList_projects.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';

interface Props {
  relay: RelayPaginationProp;
  creatorId: string;
  stages?: string[];
  projects: CreatorPastProjectsList_projects$data;
}

const Bar = () => {
  return (
    <div className={styles.bar}>
      <Text msg="bar.campaign" />
      <Text msg="bar.advertiser" />
      <Text msg="bar.status" />
      <Text msg="bar.price" />
      <div />
    </div>
  );
};

const CreatorPastProjectsList: React.FC<Props> = (props) => {
  const { projects, relay } = props;

  const intl = useIntl().formatMessage;

  const loadButton = (
    <Button
      size="lg"
      width="full"
      msg="general.show_more"
      color="normal"
      className={styles.loadButton}
      data-test="creatorPastProjectsList:button:showMore"
    />
  );

  return (
    <LoaderHandler relay={relay} loadButton={loadButton} count={10}>
      {Number(projects?.projects?.edges?.length) > 0 && <Bar />}
      {projects?.projects?.edges?.map((item) => {
        if (!item?.node) return null;

        const {
          id: projectId,
          price,
          currency,
          archivation,
          archivationRequest,
          offer,
          advertiserStage,
          campaign: { id, name: campaignName, stage: campaignStage },
        } = item.node;

        const organizationName = item.node.campaign.organization?.name;
        const organizationId = item.node.campaign.organization?.id;
        const email = item.node.campaign.creator?.email;

        const offerDate = offer?.createdAt ? createDateV2(offer.createdAt) : '';

        const campaignLink = getCampaignCreatorsLink({ campaignId: id });
        const organizationLink = getOrganizationLink({ organizationId });

        const priceText = formatValue({ value: price, format: 'currency', currency });

        let archivedReason;

        if (archivation) {
          if (archivationRequest && archivationRequest.reason) {
            archivedReason = intl(
              { id: 'creator.project.archived.reason.tooltip' },
              { reason: archivationRequest.reason }
            );
          } else {
            archivedReason = intl({ id: 'creator.project.archived.tooltip' });
          }
        }

        const archived = !archivation ? undefined : (
          <Tooltip id="archived" place="bottom" tooltipText={archivedReason}>
            <TextButton
              color="normal"
              iconName="archive"
              data-test="creatorPastProjectsList:textButton:unknown"
            />
          </Tooltip>
        );

        const link = getProjectsLink({
          projectId,
          campaignId: campaignStage === 'ACTIVE' ? id : undefined,
          organizationId,
        });

        const handleCampaignClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(campaignLink);
        };

        const handleOrganizationClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(organizationLink);
        };

        return (
          <SmartLink path={link} key={item.node.id} className={styles.item}>
            <Tooltip
              id="campaign"
              place="right"
              tooltipMsg="creator.project.campaign.tooltip"
              tooltipValues={{ name: campaignName, date: offerDate }}
            >
              <div onClick={handleCampaignClick}>
                <Text
                  color="purple"
                  text={campaignName || ''}
                  className={styles.cuttedText}
                  data-test="creatorPastProjectsList:text:unknown"
                />
              </div>
              <Text
                type="s"
                color="grey"
                text={offerDate}
                data-test="creatorPastProjectsList:text:unknown"
              />
            </Tooltip>
            <div>
              <div onClick={handleOrganizationClick}>
                <Text text={organizationName} />
              </div>
              <Text type="s" color="grey" text={email} className={styles.cuttedText} />
            </div>
            <ProjectStatus
              id={projectId}
              hasReaction={!!(offer?.acceptance?.id || offer?.rejection?.id)}
              advertiserStage={advertiserStage}
            />
            <Text text={priceText} />
            {archived}
          </SmartLink>
        );
      })}
    </LoaderHandler>
  );
};

export default createPaginationContainer(
  CreatorPastProjectsList,
  {
    projects: graphql`
      fragment CreatorPastProjectsList_projects on Creator
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        stages: { type: "[AdvertiserProjectStage!]" }
      ) {
        projects(first: $count, after: $cursor, stages: $stages)
          @connection(key: "CreatorPast_projects", filters: []) {
          edges {
            node {
              id
              advertiserStage
              archivation(side: ADVERTISER) {
                id
              }
              archivationRequest {
                reason
              }
              campaign {
                id
                name
                brand {
                  name
                }
                organization {
                  id
                  name
                }
                creator {
                  email
                }
                stage
              }
              price
              currency
              brief {
                ... on V2Brief {
                  productShipmentRequired
                }
              }
              productShipmentCompletion {
                id
              }
              offer {
                createdAt
                acceptance {
                  id
                }
                rejection {
                  id
                  comment
                }
                readMark {
                  id
                }
              }
              launch {
                id
              }
              completion {
                id
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.projects.projects;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        creatorId: props.creatorId,
        stages: props.stages,
      };
    },
    query: graphql`
      query CreatorPastProjectsListPaginationQuery(
        $count: Int!
        $cursor: String
        $creatorId: ID!
        $stages: [AdvertiserProjectStage!]
      ) {
        creator(id: $creatorId) {
          ...CreatorPastProjectsList_projects
            @arguments(count: $count, cursor: $cursor, stages: $stages)
        }
      }
    `,
  }
);
