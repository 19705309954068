import React, { useContext, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';

import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import Section from '../components/Section/Section';

import updateBrief from 'Mutations/UpdateBrief.Mutation';
import { OptionalExtras_campaign$key } from 'GraphTypes/OptionalExtras_campaign.graphql';
import { PRODUCT_SEEDING } from 'Constants/general';

interface Props {
  campaign: OptionalExtras_campaign$key;
}

const OptionalExtras: React.FC<Props> = (props) => {
  const [briefState] = useContext(BriefContext);
  const { campaign } = props;
  const data = useFragment(
    graphql`
      fragment OptionalExtras_campaign on Campaign {
        type

        brief {
          id
          __typename
          ... on V2Brief {
            publishingRequired
            hashtags
            brandAccountName
          }
        }
      }
    `,
    campaign
  );

  const { brief, type } = data;

  if (!brief) {
    return null;
  }

  const { id: briefId, hashtags, brandAccountName } = brief;

  useEffect(() => {
    if (type === PRODUCT_SEEDING && !brief.publishingRequired) {
      updateBrief({ id: briefId, hashtags: null, brandAccountName: null });
    }
  }, [type, brief.publishingRequired]);

  if (type === PRODUCT_SEEDING) {
    if (
      (brief.publishingRequired && !briefState.deliverablesInfoFilled) ||
      !brief.publishingRequired
    ) {
      return null;
    }
  }

  if (briefState.isStepAvailable.creativesInfo && !briefState.creativesInfoFilled) {
    return null;
  }

  const handleBriefChange = (data: any) => {
    updateBrief({ id: briefId, ...data });
  };

  return (
    <Section titleMsg="brief_template.section.optional">
      <Field
        element="input"
        name="brandAccountName"
        title="brief_template.optional_extras.references.brand.title"
        description="brief_template.optional_extras.references.brand.descr"
        elementData={{
          defaultValue: brandAccountName,
          onBlur: handleBriefChange,
        }}
        isOptional={true}
      />
      <Field
        element="input"
        name="hashtags"
        title="brief_template.optional_extras.references.hashtag.title"
        description="brief_template.optional_extras.references.hashtag.descr"
        elementData={{
          defaultValue: hashtags,
          onBlur: handleBriefChange,
        }}
        isOptional={true}
      />
    </Section>
  );
};

export default OptionalExtras;
