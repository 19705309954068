import React, { useState, Context, PropsWithChildren } from 'react';

import { DrawerContextType, DrawerParams } from './Drawer.Context';
import type { DrawerId } from './types';

type Props = {
  context: Context<DrawerContextType>;
};

const DrawerProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, context: Context } = props;
  const [drawerState, setDrawerState] = useState<{
    activeId: DrawerId | null;
    prevId: DrawerId | null;
  }>({
    activeId: null,
    prevId: null,
  });
  const [drawersParams, setDrawersParams] = useState<Record<DrawerId, DrawerParams>>({});

  const openDrawer: DrawerContextType['openDrawer'] = (
    drawerId,
    params,
    showPrevDrawerAfterClose
  ) => {
    setDrawerState((prev) => ({
      activeId: drawerId,
      prevId: showPrevDrawerAfterClose ? prev.activeId : null,
    }));
    if (params) {
      setDrawersParams((prev) => ({
        ...prev,
        [drawerId]: params,
      }));
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // @desc Temporary value
  window.openDrawer = openDrawer;

  const closeDrawer: DrawerContextType['closeDrawer'] = (drawerId) => {
    if (drawerState.activeId === drawerId) {
      setDrawerState((prev) => ({
        activeId: prev.prevId,
        prevId: null,
      }));
    }
    if (drawersParams[drawerId]) {
      setDrawersParams((prev) => {
        const newParams = { ...prev };
        delete newParams[drawerId];
        return newParams;
      });
    }
  };

  const setDrawerParams: DrawerContextType['setDrawerParams'] = (drawerId, params) => {
    setDrawersParams((prev) => ({
      ...prev,
      [drawerId]: params,
    }));
  };

  const setActiveDrawerParams: DrawerContextType['setActiveDrawerParams'] = (params) => {
    if (!drawerState.activeId) return;
    setDrawersParams((prev) => ({
      ...prev,
      [drawerState.activeId as DrawerId]: params,
    }));
  };

  const contextValue: DrawerContextType = {
    idPrefix: '__Drawer__',
    activeDrawerId: drawerState.activeId,
    openDrawer,
    closeDrawer,
    params: drawersParams,
    setDrawerParams,
    setActiveDrawerParams,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { DrawerProvider };
