import React, { PropsWithChildren, useContext } from 'react';
import { graphql, fetchQuery } from 'react-relay';

import environment from 'Api/Environment';
import ConnectFb from 'Molecules/ConnectFb/ConnectFb';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { RequestBcaPermissionMutation$data } from 'GraphTypes/RequestBcaPermissionMutation.graphql';
import { ActivateBcaQuery as QueryType } from 'GraphTypes/ActivateBcaQuery.graphql';

const ActivateBcaQuery = graphql`
  query ActivateBcaQuery {
    facebook {
      instagramBusinessAccounts {
        id
        username
        profilePictureUrl
      }
    }
  }
`;

interface Props {
  projectId: string;
  disabled?: boolean;
  simpleView?: boolean;
  onCheckingConnectionPossibility?: () => boolean;
  onBcaRequestPermissionError?: (data?: any) => void;
  onBcaRequestPermissionDone?: (data?: RequestBcaPermissionMutation$data) => void;
}

const ActivateBca: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, projectId, simpleView, disabled, onCheckingConnectionPossibility } = props;

  const { openDrawer } = useContext(DrawerContext);

  const handleConnectDone = (socialIdentityId: string) => {
    if (disabled) return;
    fetchQuery<QueryType>(environment, ActivateBcaQuery, {}).subscribe({
      next: () => {
        openDrawer(`project-bca-connection-${projectId}`, undefined, simpleView ? true : undefined);
      },
    });
  };

  return (
    <ConnectFb
      callback={handleConnectDone}
      onCheckingConnectionPossibility={onCheckingConnectionPossibility}
      additionalPermissions={[
        'instagram_branded_content_brand',
        'instagram_branded_content_ads_brand',
      ]}
    >
      {children}
    </ConnectFb>
  );
};

export default ActivateBca;
