import React, { useEffect, useRef } from 'react';
import { PreloadedQuery, graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { chatListPageCount } from '../ProjectsSidebar';

import styles from './ProjectsList.pcss';
import ProjectItem from './ProjectItem/ProjectItem';

import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { ProjectsListQuery as QueryType } from 'GraphTypes/ProjectsListQuery.graphql';
import { ProjectsListComponent_chats$key } from 'GraphTypes/ProjectsListComponent_chats.graphql';

export const ProjectsListQuery = graphql`
  query ProjectsListQuery(
    $first: Int!
    $withUnreadEvents: Boolean
    $shortlisted: Boolean
    $archived: Boolean
    $brandIds: [ID!]
    $organizationId: ID!
    $campaignIds: [ID!]
    $stages: [AdvertiserProjectStage!]
    $textQuery: String
    $after: String
    $draftDeadlineIntervals: [DateInterval!]
    $publicationDeadlineIntervals: [DateInterval!]
  ) {
    ...ProjectsListComponent_chats
      @arguments(
        first: $first
        after: $after
        withUnreadEvents: $withUnreadEvents
        shortlisted: $shortlisted
        archived: $archived
        brandIds: $brandIds
        campaignIds: $campaignIds
        textQuery: $textQuery
        stages: $stages
        organizationId: $organizationId
        draftDeadlineIntervals: $draftDeadlineIntervals
        publicationDeadlineIntervals: $publicationDeadlineIntervals
      )
  }
`;

interface Props {
  selectedProjectId?: string;
  organizationId?: string;
  queryReference: PreloadedQuery<QueryType>;
}

const ProjectsList: React.FC<Props> = (props) => {
  const { selectedProjectId, queryReference } = props;

  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const queryData = usePreloadedQuery<QueryType>(ProjectsListQuery, queryReference);

  const { data, loadNext, isLoadingNext, hasNext } = usePaginationFragment<
    QueryType,
    ProjectsListComponent_chats$key
  >(
    graphql`
      fragment ProjectsListComponent_chats on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        archived: { type: "Boolean" }
        withUnreadEvents: { type: "Boolean" }
        brandIds: { type: "[ID!]" }
        campaignIds: { type: "[ID!]" }
        stages: { type: "[AdvertiserProjectStage!]" }
        shortlisted: { type: "Boolean" }
        textQuery: { type: "String", defaultValue: "" }
        after: { type: "String" }
        organizationId: { type: "ID!" }
        draftDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
        publicationDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
      )
      @refetchable(queryName: "ProjectListQuery") {
        organization(id: $organizationId) {
          projects(
            orderBy: ACTIVITY
            first: $first
            archived: $archived
            withUnreadEvents: $withUnreadEvents
            brandIds: $brandIds
            campaignIds: $campaignIds
            shortlisted: $shortlisted
            stages: $stages
            textQuery: $textQuery
            after: $after
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
          ) @connection(key: "ProjectsList_projects") {
            edges {
              node {
                id
                ...ProjectItem_project
              }
            }
          }
        }
      }
    `,
    queryData
  );

  const list = data?.organization?.projects?.edges;

  useEffect(() => {
    const containerEl = scrollContainer.current;
    const scrollHandler = (e: Event) => {
      if (!containerEl) return;
      const containerHeight = containerEl.scrollHeight - containerEl.clientHeight;
      const scrollOffset = containerHeight - (e.target as HTMLDivElement).scrollTop;
      if (!isLoadingNext && scrollOffset < containerHeight / 2 && hasNext) {
        loadNext(chatListPageCount);
      }
    };
    if (containerEl) {
      containerEl.addEventListener('scroll', scrollHandler);
    }
    return () => {
      containerEl?.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollContainer.current, isLoadingNext, loadNext, queryData, hasNext]);

  return (
    <div className={styles.root} ref={scrollContainer}>
      {!list?.length && (
        <Text
          type="label"
          msg="projects.list.empty_list.with_filters"
          className={styles.emptyTitle}
          data-test="projectsList:text:withFilters"
        />
      )}
      {list?.map((item) => {
        if (!item?.node) return null;
        const { id } = item?.node;

        const isActive = selectedProjectId === id;

        return <ProjectItem key={id} project={item.node} isActive={isActive} />;
      })}
      {isLoadingNext && (
        <div className={styles.loader}>
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
};

export default ProjectsList;
