import React, { useMemo } from 'react';
import cn from 'classnames';
import track from 'react-tracking';
import classNames from 'classnames';

import { AddonSettings, ExtendedPlanId, PlanType, addonsKeyMap } from '../../data';
import BenefitList from '../../BenefitList/BenefitList';
import CreatorCostList from '../../CreatorCostList/CreatorCostList';
import AddonsList from '../../AddonsList/AddonsList';
import { AddonsSubscriptionType } from '../../BillingPlans';

import styles from './Plan.pcss';
import PlanIntervalSwitch from './PlanIntervalSwitch';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import Button from 'Atoms/NewButton/Button';
import { BOOK_A_DEMO_LINK_RU } from 'Constants/general';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { SubscriptionInterval as SubscriptionIntervalType } from 'Types/enums';
import TextWithTaxInfoTooltip from 'AdvertiserPage/BillingPlans/TextWithTaxInfoTooltip/TextWithTaxInfoTooltip';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import { buttonColor } from 'Types/common';

interface Props {
  isActive: boolean;
  currency: string;
  isUpgarde: boolean;
  place: 'modal' | 'plans';
  admin?: boolean;
  isPromocodeValid?: boolean;
  interval: SubscriptionIntervalType;
  addons: AddonSettings[];
  nextPayment?: JSX.Element;
  colorTheme?: 'default' | 'sky' | 'pink' | 'dark' | 'sun' | 'ocean';
  onPlanBuyClick: (data: object) => void;
  onPlanIntervalChanged: (planId: ExtendedPlanId, interval: SubscriptionIntervalType) => void;
  onPlanAddonsChanged: (planId: ExtendedPlanId, addons: AddonSettings[]) => void;
  defaultAddonsValue: AddonsSubscriptionType;
  isCurrentPlan: boolean;
}

const Plan: React.FC<PlanType & Props> = (props) => {
  const {
    planId,
    isActive,
    place,
    title,
    descr,
    price,
    priceElement,
    showSummaryPrice,
    pricePer,
    btnMsg: btnMsgValue,
    btnValues,
    shortBenefitsList,
    creatorCostList,
    onPlanBuyClick,
    onPlanIntervalChanged,
    onPlanAddonsChanged,
    currency,
    priceDescr,
    isUpgarde,
    nextPayment,
    disabledData,
    interval,
    addons,
    colorTheme = 'default',
    disabledChangeInterval,
    withReferButton,
    availableAddons,
    defaultAddonsValue,
    isCurrentPlan,
  } = props;

  const addonList = availableAddons?.map((staticAddon) => {
    const subscriptionKey = addonsKeyMap[staticAddon.key] as keyof AddonsSubscriptionType;
    const addonData = addons.find((stateAddon) => staticAddon.key === stateAddon.key);
    const defaultValue =
      defaultAddonsValue[subscriptionKey] === null ? Infinity : defaultAddonsValue[subscriptionKey];
    return {
      ...staticAddon,
      ...addonData,
      defaultValue: (isCurrentPlan ? defaultValue : staticAddon.defaultValue) || 1,
    };
  });

  const addonsPrice = addons?.reduce((sum, addon) => {
    if (
      addon.value !== addon.defaultValue &&
      addon.value &&
      addon.value > addon.defaultValue &&
      addon.value !== Infinity
    ) {
      return (sum += addon.price * (addon.value - addon.defaultValue));
    }
    return sum;
  }, 0);

  const actualPrice = price !== undefined ? createSum(price + addonsPrice, currency) : undefined;
  const priceData =
    actualPrice !== undefined ? { text: actualPrice } : { msg: 'billing_plans.custom' };
  const isAnnually = interval === SubscriptionIntervalType.YEARLY;

  const msgPlanId = planId?.toLowerCase();

  const btnMsg = useMemo<string>(() => {
    const defaultChooseBtnMsg =
      btnMsgValue || (isUpgarde ? 'billing_plans.plans.upgrade' : 'billing_plans.plans.choose');

    if (isActive) {
      return 'billing_plans.your_plan';
    }
    if (actualPrice) {
      return defaultChooseBtnMsg;
    }

    return 'billing_plans.pro_btn';
  }, [isActive, actualPrice]);

  const isButtonDisabled = isActive;

  const summaryPrice = useMemo(() => {
    if (!price) return null;
    const key = isAnnually ? 'annually' : 'quarterly';
    const values = {
      quarterly: (
        <Text
          msg="billing_plans.plans.price.per_quarter"
          formatValues={{ price: createSum((price + addonsPrice) * 3, currency) }}
          className={styles.summaryPrice}
          data-test="plan:text:perQuarter"
        />
      ),
      annually: (
        <Text
          msg="billing_plans.plans.price.per_year"
          formatValues={{ price: createSum((price + addonsPrice) * 12, currency) }}
          className={styles.summaryPrice}
          data-test="plan:text:perYear"
        />
      ),
    };

    return values[key];
  }, [price, addonsPrice, isAnnually, currency]);

  const handleBuyClick = () => {
    amplitude.sendEvent({
      id: '59',
      category: 'billing',
      name: 'choose_your_plan',
      param: { planId, interval, place },
    });
    if (actualPrice) {
      onPlanBuyClick({
        choosenPlanId: planId,
      });
    } else {
      window.open(BOOK_A_DEMO_LINK_RU);
    }
  };

  const handlePlanIntervalChange = (interval: SubscriptionIntervalType) => {
    onPlanIntervalChanged(planId, interval);
  };

  const handleAddonChange = (addons: AddonSettings[]) => {
    onPlanAddonsChanged(planId, addons);
  };

  const handleReferButton = () => {
    window.open('https://insense.pro/partners', '_blank');
  };

  return (
    <div
      className={cn(styles.root, styles[`${colorTheme}Color`], {
        [styles.withReferBlock]: withReferButton,
      })}
    >
      <div className={styles.descriptionWrap}>
        <div className={styles.nameWrap}>
          <Text
            type="h2"
            msg={title}
            className={cn(styles.title, {
              [styles.skyColor]: colorTheme === 'sky',
              [styles.pinkColor]: colorTheme === 'pink',
              [styles.whiteColor]: colorTheme === 'dark' || colorTheme === 'ocean',
              [styles.sunColor]: colorTheme === 'sun',
            })}
            data-test="plan:text:unknown"
          />
        </div>
        <div className={styles.price}>
          <Text
            msg={'billing_plans.plans.price.from'}
            className={styles.periodText}
            data-test="plan:text:periodText"
          />
          <div className={styles.priceContainer}>
            {priceElement}
            {!priceElement && (
              <Text
                className={classNames(styles.priceText, styles[colorTheme])}
                type="md"
                {...priceData}
                data-test="plan:text:unknown"
              />
            )}
            {actualPrice !== undefined && !priceElement && (
              <TextWithTaxInfoTooltip
                colorTheme={colorTheme}
                tooltipInfoProps={{
                  id: `plan.${planId}.${interval}.tax.tooltip`,
                  color: colorTheme === 'dark' ? 'grey100' : 'grey100',
                }}
              >
                <Text
                  msg={pricePer ? pricePer : 'billing_plans.plans.per.month'}
                  type="md"
                  className={styles.periodText}
                  data-test="plan:text:periodText"
                />
              </TextWithTaxInfoTooltip>
            )}
            {disabledChangeInterval && (
              <div className={styles.trialUpgrade}>
                <Text
                  type="sm"
                  msg="billing_plans.plans.trial_update"
                  data-test="plan:text:trialUpdate"
                />
              </div>
            )}
          </div>
          <div
            className={cn(styles.summaryPrice, {
              [styles.hidden]: !showSummaryPrice,
            })}
          >
            {summaryPrice}
          </div>
        </div>
        <div className={styles.switchWrapper}>
          {!disabledChangeInterval && (
            <PlanIntervalSwitch
              interval={interval}
              onPlanIntervalChanged={handlePlanIntervalChange}
            />
          )}
        </div>
        <div className={styles.descriptionWrapper}>
          <Text
            msg={descr}
            type="md"
            className={styles.description}
            data-test="plan:text:description"
          />
          {disabledData && (
            <Tooltip {...disabledData.tooltipData}>
              <Button
                width="full"
                size="xl"
                color={
                  {
                    default: 'primary',
                    sky: 'primary',
                    pink: 'primary',
                    dark: 'primary',
                    ocean: 'primary',
                    sun: 'primary',
                  }[colorTheme] as buttonColor
                }
                msg={btnMsg}
                className={cn(
                  styles.button,
                  styles.submit,
                  {
                    [styles.withBorder]: colorTheme === 'dark' || colorTheme === 'ocean',
                  },
                  styles.disabledButton
                )}
                {...disabledData.buttonData}
                data-test="plan:button:unknown"
              />
            </Tooltip>
          )}
          {!disabledData && (
            <Button
              width="full"
              size="xl"
              color={
                {
                  default: 'primary',
                  sky: 'primary',
                  pink: 'primary',
                  dark: 'primary',
                  ocean: 'primary',
                  sun: 'primary',
                }[colorTheme] as buttonColor
              }
              disabled={isButtonDisabled}
              theme={colorTheme === 'dark' || colorTheme === 'ocean' ? 'dark' : 'light'}
              msg={btnMsg}
              msgValues={btnValues}
              className={cn(styles.button, styles.submit, {
                [styles.disabledButton]: isButtonDisabled,
                [styles.disabledButtonDark]:
                  isButtonDisabled && (colorTheme === 'dark' || colorTheme === 'ocean'),
                [styles.withBorder]: colorTheme === 'dark' || colorTheme === 'ocean',
              })}
              onClick={handleBuyClick}
              data-test="plan:button:unknown"
            />
          )}
        </div>
        {addonList?.length && <AddonsList items={addonList} onChange={handleAddonChange} />}

        <BenefitList
          items={shortBenefitsList}
          colorTheme={colorTheme}
          tooltipInfoProps={{
            color: colorTheme === 'dark' || colorTheme === 'ocean' ? 'grey50' : undefined,
          }}
        />
      </div>
      <div className={styles.creatorCostWrapper}>
        <CreatorCostList
          items={creatorCostList || []}
          colorTheme={colorTheme}
          tooltipInfoProps={{
            color: colorTheme === 'dark' || colorTheme === 'ocean' ? 'grey50' : undefined,
          }}
        />
      </div>
      <div className={styles.priceWrap}>
        {(nextPayment || priceDescr) && (
          <div className={styles.disclaimer}>{nextPayment || priceDescr}</div>
        )}
      </div>
      <div className={styles.paymentInfoContainer}>
        {!disabledChangeInterval && (
          <div className={styles.subscriptionPaymentWrap}>
            {(interval === SubscriptionIntervalType.TRIMONTHLY ||
              interval === SubscriptionIntervalType.YEARLY) && (
              <Text
                type="md"
                className={styles.subscriptionPaymentText}
                msg={
                  interval === SubscriptionIntervalType.TRIMONTHLY
                    ? `billing_plans.plan.${msgPlanId}.payment_info`
                    : `billing_plans.plan.${msgPlanId}.payment_info.annually`
                }
                data-test="plan:text:subscriptionPaymentText"
              />
            )}
          </div>
        )}
        <div className={styles.referButtonWrapper}>
          {withReferButton && (
            <div className={styles.referButtonBlock}>
              <Text
                type="md"
                msg="billing_plans.plans.refer.clients"
                data-test="plan:text:clients"
              />
              <TextButton
                msg="billing_plans.plans.refer.learnmore"
                className={styles.learnMoreButton}
                onClick={handleReferButton}
                data-test="plan:textButton:learnmore"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default track()(Plan);
