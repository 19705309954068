import moment from 'moment';
import React from 'react';

import BadgeListItem, { BadgeListItemProps } from './BadgeListItem/BadgeListItem';
import FilterFormCountryListQuery from './Queries/FilterFormCountryList';
import FilterFormLanguageListQuery from './Queries/FilterFormLanguageList';
import FilterFormCategoryListQuery from './Queries/FilterFormCategoryList';
import FilterFormGenderListQuery from './Queries/FilterFormGenderList';
import FilterFormEthnicitiyListQuery from './Queries/FilterFormEthnicitiyList';
import FilterFormBadgeListQuery from './Queries/FilterFormBadgeList';
import FilterFormHairListQuery from './Queries/FilterFormHairList';
import FilterFormPetsListQuery from './Queries/FilterFormPetsList';
import FilterFormBodyListQuery from './Queries/FilterFormBodyList';
import FilterFormAgeListQuery from './Queries/FilterFormAgeList';
import FilterFormCitiesListQuery from './Queries/FilterFormCitiesList';
import FilterFormTagListQuery from './Queries/FilterFormTagList';

import { CONTENT, INSTAGRAM, TIKTOK } from 'Constants/general';
import { SearchQueryInput } from 'GraphTypes/DiscoverySearchResultsContainerQuery.graphql';

export enum FilterFormFilterType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  LIST = 'LIST',
  ITEMS = 'ITEMS',
  INPUT_RANGE = 'INPUT_RANGE',
  FLAT_LIST = 'FLAT_LIST',
  TOGGLE = 'TOGGLE',
  FLAG_LIST = 'FLAG_LIST',
}

export const FilterFormList = {
  textQuery: {
    type: FilterFormFilterType.INPUT,
    fieldKey: 'textQuery',
    fieldProps: {
      placeholderMsg: 'filter.form.placeholder.search',
      rightIcon: 'Search-loop',
    },
  },
  creatorTypes: {
    fieldKey: 'creatorTypes',
    type: FilterFormFilterType.LIST,
    renderWithDropdown: true,
    isMultiplicable: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.platform',
    },
    handleValueChange: (value?: string) => {
      if (!value) {
        return [INSTAGRAM, TIKTOK, CONTENT];
      }
      return value;
    },
    items: [
      {
        id: undefined,
        name: 'All platforms',
      },
      {
        id: INSTAGRAM,
        name: 'Instagram',
      },
      {
        id: TIKTOK,
        name: 'TikTok',
      },
    ],
  },
  creatorCountryIds: {
    fieldKey: 'creatorCountryIds',
    type: FilterFormFilterType.LIST,
    renderWithDropdown: true,
    isMultiselect: true,
    withSearch: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.country',
    },
    itemsQuery: FilterFormCountryListQuery,
    additionalGroup: {
      title: 'filter.form.placeholder.popular',
      items: [
        {
          id: '9969a401-2a5e-4dd5-b154-9de36680feda',
          name: 'United States',
        },
        {
          id: '25b624e7-76f6-421f-9fb0-dabbfe9c512c',
          name: 'United Kingdom',
        },
        {
          id: '36f1bcde-b4c5-4ca3-a6f4-bb38cb72d9f5',
          name: 'Canada',
        },
      ],
    },
  },
  creatorLanguageIds: {
    fieldKey: 'creatorLanguageIds',
    type: FilterFormFilterType.LIST,
    renderWithDropdown: true,
    isMultiselect: true,
    withSearch: true,
    onlyForAdmin: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.languages',
    },
    itemsQuery: FilterFormLanguageListQuery,
  },
  creatorAgeRangeIds: {
    fieldKey: 'creatorAgeRangeIds',
    renderWithDropdown: true,
    type: FilterFormFilterType.LIST,
    itemsQuery: FilterFormAgeListQuery,
    isShowTitleLabel: true,
    title: 'filter.form.placeholder.creator_age',
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.creator_age',
    },
  },
  creatorBirthdate: {
    parentKey: 'creatorBirthdate',
    type: FilterFormFilterType.INPUT_RANGE,
    renderWithDropdown: true,
    reverseValues: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.age',
    },
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return moment().subtract(Number(value), 'years').format('YYYY-MM-DD');
    },
    fields: [
      {
        fieldKey: 'creatorBirthdateTo',
        placeholderMsg: 'filter.form.placeholder.from',
        maxLength: 2,
      },
      {
        fieldKey: 'creatorBirthdateFrom',
        placeholderMsg: 'filter.form.placeholder.to',
        maxLength: 2,
      },
    ],
  },
  categoryIds: {
    fieldKey: 'categoryIds',
    type: FilterFormFilterType.LIST,
    isMultiselect: true,
    withSearch: true,
    renderWithDropdown: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.categories',
    },
    itemsQuery: FilterFormCategoryListQuery,
  },
  engagementRate: {
    parentKey: 'engagementRate',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.engagement_rate',
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number((Number(value) / 100).toFixed(2));
    },
    fields: [
      {
        fieldKey: 'engagementRateFrom',
        placeholderMsg: 'filter.form.placeholder.from',
        rightSymbol: '%',
        maxLength: 3,
      },
      {
        fieldKey: 'engagementRateTo',
        placeholderMsg: 'filter.form.placeholder.to',
        rightSymbol: '%',
        maxLength: 3,
      },
    ],
  },
  followers: {
    parentKey: 'followers',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.followers',
    onlyForAdmin: true,
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'followersFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'followersTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
  realFollowers: {
    parentKey: 'realFollowers',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.realFollowers',
    relatedWith: {
      creatorTypes: INSTAGRAM,
    },
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'realFollowersFrom',
        placeholderMsg: 'filter.form.placeholder.from',
        rightSymbol: '%',
      },
      {
        fieldKey: 'realFollowersTo',
        placeholderMsg: 'filter.form.placeholder.to',
        rightSymbol: '%',
      },
    ],
  },
  viewsPerPost: {
    parentKey: 'viewsPerPost',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.avg_views',
    relatedWith: {
      creatorTypes: TIKTOK,
    },
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'viewsPerPostFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'viewsPerPostTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
  creatorGenderId: {
    fieldKey: 'creatorGenderId',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.creator_gender',
    itemsQuery: FilterFormGenderListQuery,
    isShowTitleLabel: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
  },
  creatorEthnicityIds: {
    fieldKey: 'creatorEthnicityIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.creator_ethnicities',
    itemsQuery: FilterFormEthnicitiyListQuery,
    isShowTitleLabel: true,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
  },
  creatorAffiliates: {
    parentKey: 'creatorAffiliates',
    type: FilterFormFilterType.FLAG_LIST,
    title: 'filter.form.placeholder.creator_affiliates',
    fields: [
      {
        id: 'includeAmazonStorefrontLink',
        fieldKey: 'includeAmazonStorefrontLink',
        name: 'Amazon store',
      },
      {
        id: 'withTiktokShop',
        fieldKey: 'withTiktokShop',
        name: 'TikTok Shop',
      },
    ],
  },
  creatorBadgeIds: {
    fieldKey: 'creatorBadgeIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.creator_badges',
    itemsQuery: FilterFormBadgeListQuery,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
    renderItem: (itemProps: BadgeListItemProps) => {
      return <BadgeListItem {...itemProps} key={itemProps.name} />;
    },
  },
  creatorHairTypeIds: {
    fieldKey: 'creatorHairTypeIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.creator_hair_types',
    itemsQuery: FilterFormHairListQuery,
    onlyForAdmin: true,
    isShowTitleLabel: true,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
  },
  creatorAnimalIds: {
    fieldKey: 'creatorAnimalIds',
    type: FilterFormFilterType.FLAT_LIST,
    title: 'filter.form.placeholder.creator_animals',
    itemsQuery: FilterFormPetsListQuery,
    onlyForAdmin: true,
    isShowTitleLabel: true,
    isMultiselect: true,
  },
  creatorBodyTypeIds: {
    fieldKey: 'creatorBodyTypeIds',
    type: FilterFormFilterType.FLAT_LIST,
    title: 'filter.form.placeholder.creator_body_types',
    itemsQuery: FilterFormBodyListQuery,
    onlyForAdmin: true,
    isShowTitleLabel: true,
    isMultiselect: true,
  },
  audienceGenderId: {
    fieldKey: 'audienceGenderId',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.audience_gender',
    itemsQuery: FilterFormGenderListQuery,
    isShowTitleLabel: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
    percentageField: {
      fieldKey: 'audienceGenderPercentage',
      isDisabled: (filters: SearchQueryInput) => {
        return filters.owned === false;
      },
    },
    isDisabled: (filters: SearchQueryInput) => {
      return filters.owned === false;
    },
  },
  ageRangeIds: {
    fieldKey: 'ageRangeIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.audience_age',
    itemsQuery: FilterFormAgeListQuery,
    isShowTitleLabel: true,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
    percentageField: {
      fieldKey: 'audienceAgeRangePercentage',
      isDisabled: (filters: SearchQueryInput) => {
        return filters.owned === false;
      },
    },
    isDisabled: (filters: SearchQueryInput) => {
      return filters.owned === false;
    },
  },
  countryIds: {
    fieldKey: 'countryIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.audience_country',
    itemsQuery: FilterFormCountryListQuery,
    isShowTitleLabel: true,
    isMultiselect: true,
    withSearch: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
    percentageField: {
      fieldKey: 'audienceCountryPercentage',
      isDisabled: (filters: SearchQueryInput) => {
        return filters.owned === false;
      },
    },
    additionalGroup: {
      title: 'filter.form.placeholder.popular',
      items: [
        {
          id: '9969a401-2a5e-4dd5-b154-9de36680feda',
          name: 'United States',
        },
        {
          id: '25b624e7-76f6-421f-9fb0-dabbfe9c512c',
          name: 'United Kingdom',
        },
        {
          id: '36f1bcde-b4c5-4ca3-a6f4-bb38cb72d9f5',
          name: 'Canada',
        },
      ],
    },
  },
  cityIds: {
    fieldKey: 'cityIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.audience_city',
    itemsQuery: FilterFormCitiesListQuery,
    filterQueryVariable: 'query',
    withSearch: true,
    onlyForAdmin: true,
    isShowTitleLabel: true,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
    percentageField: {
      fieldKey: 'audienceCityPercentage',
    },
    additionalGroup: {
      title: 'filter.form.placeholder.popular',
      items: [
        {
          id: 'fed78177-aa8d-4549-89c9-0c3c8a122513',
          name: 'New York City',
        },
        {
          id: '9c77b555-395d-429c-ba60-1cdffee0544a',
          name: 'Chicago',
        },
        {
          id: 'a43a5a36-e5a2-4da3-9f27-e5accce8bd40',
          name: 'Seattle',
        },
      ],
    },
  },
  audienceEthnicityIds: {
    fieldKey: 'audienceEthnicityIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.audience_ethnicity',
    itemsQuery: FilterFormEthnicitiyListQuery,
    isShowTitleLabel: true,
    isMultiselect: true,
    onlyForAdmin: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
  },
  creatorTagIds: {
    fieldKey: 'creatorTagIds',
    type: FilterFormFilterType.SELECT,
    title: 'filter.form.placeholder.creator_tags',
    itemsQuery: FilterFormTagListQuery,
    onlyForAdmin: true,
    withSearch: true,
    isShowTitleLabel: true,
    isMultiselect: true,
    dropdownProps: {
      placeholderMsg: 'filter.form.placeholder.any',
    },
  },
  campaignsCompletionsCount: {
    parentKey: 'campaignsCompletionsCount',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.campaigns_completions_count',
    onlyForAdmin: true,
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'campaignsCompletionsCountFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'campaignsCompletionsCountTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
  vetted: {
    fieldKey: 'owned',
    type: FilterFormFilterType.TOGGLE,
    title: 'filter.form.placeholder.vetted',
    img: require('Images/icons/vetted.png'),
    fieldProps: {
      size: 16,
    },
  },
  unlisted: {
    fieldKey: 'includeHidden',
    type: FilterFormFilterType.TOGGLE,
    onlyForAdmin: true,
    title: 'filter.form.placeholder.unlisted',
    fieldProps: {
      size: 16,
    },
  },
  collaborated: {
    fieldKey: 'onlyCreatorsWorkedWith',
    type: FilterFormFilterType.TOGGLE,
    title: 'filter.form.placeholder.collaborated',
    fieldProps: {
      size: 16,
    },
    isDisabled: (filters: SearchQueryInput) => {
      return filters.owned === false;
    },
  },
  postsReach: {
    parentKey: 'postsReach',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.post_reach',
    relatedWith: {
      creatorTypes: INSTAGRAM,
    },
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'postsReachFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'postsReachTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
  storiesReach: {
    parentKey: 'storiesReach',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.stories_reach',
    relatedWith: {
      creatorTypes: INSTAGRAM,
    },
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'storiesReachFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'storiesReachTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
  postPrice: {
    parentKey: 'postPrice',
    type: FilterFormFilterType.INPUT_RANGE,
    title: 'filter.form.placeholder.post_price',
    handleValueChange: (value?: string) => {
      if (Number.isNaN(Number(value))) {
        return null;
      }
      return Number(value);
    },
    fields: [
      {
        fieldKey: 'postPriceFrom',
        placeholderMsg: 'filter.form.placeholder.from',
      },
      {
        fieldKey: 'postPriceTo',
        placeholderMsg: 'filter.form.placeholder.to',
      },
    ],
  },
};
