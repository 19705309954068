import React from 'react';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'motion/react';
import { Text } from '@insensepro/ui-kit';

import styles from './Tab.pcss';

import NewNotification from 'Atoms/NewNotification/NewNotification';
import poly from 'Images/icons/poly.svg';
import Text from 'Components/ui/Text/Text';

export interface Props {
  id: string;
  msg: string;
  active?: boolean;
  unreadCount?: number;
  onClick?: (id: string) => void;
  tabMessage?: { targetTab: string; value: number | string };
  tabKey?: string;
  notificationEnabled?: boolean;
  hasNotification?: boolean;
}

const Tab: React.FC<Props> = (props) => {
  const {
    id,
    msg,
    active,
    onClick,
    tabMessage,
    unreadCount,
    notificationEnabled,
    hasNotification,
  } = props;

  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <div onClick={handleClick} className={classnames(styles.item, { [styles.active]: !!active })}>
      <Text
        type="p"
        weight="bold"
        msg={msg}
        color={active ? 'textMainDefault' : 'textMainTextTertiary'}
        className={styles.title}
      />
      {!!unreadCount && <NewNotification count={unreadCount} isActive={true} />}
      {notificationEnabled && (
        <AnimatePresence>
          {hasNotification && (
            <motion.div
              className={styles.notification}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <img src={poly} alt="" className={styles.poly} />
              <Text
                msg={tabMessage?.value?.toString()}
                color="textMainInverseDefault"
                type="c1"
                weight="medium"
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Tab;
